import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const {markdownRemark} = data; // data.markdownRemark holds your post data
  const {frontmatter, html} = markdownRemark;
  return (
    <Layout fullMenu>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>{frontmatter.title}</h2>
            <p>{frontmatter.summary}</p>
          </div>
        </header>

        <div className="wrapper">
          <div className="inner" dangerouslySetInnerHTML={{__html: html}} />
          <div className="inner" align="left"><i>{frontmatter.date}</i></div>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query($pageId: String!) {
    markdownRemark(id: {eq: $pageId}) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        summary
      }
    }
  }
`;
